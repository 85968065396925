import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/news_logo.svg";
import DealsHeader from "../DealsHeader/DealsHeader";
import SearchBar from "../Search/SearchBar";
import "./Header.css";
import HeaderFeatures from "./HeaderFeatures/HeaderFeatures";
import HeaderData from "./HeaderData";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  guestUserLogin,
  getMetaListApi,
  userLogoutClear,
} from "../../store/slices/UserSlice";
import ClassifiedCountry from "../ClassiFieds/ClassifiedCountry";
import Loader from "../../utils/Loader/Loader";
import { STATUS_CODES } from "../../utils/StatusCode";
import SearchTotalCount from "../Search/SearchTotalCount";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FiSearch } from "react-icons/fi";
import TopHeader from "./TopHeader/TopHeader"
import { BANNERS_WEB_VIEW } from "../../utils/Constants";
import SublyApi from "../../helpers/Api";
import { Toast } from "../../utils/Toaster";
import { useTranslation } from "react-i18next";

//-------Create a Header component--------
function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isShown, setIsShown] = useState(false);
  const [headerBanner, seHeaderBanner] = useState([]);
  const { t } = useTranslation();

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 2000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 492,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const check = location.pathname.match("deal")

  const handleClick = () => {
    setIsShown(!isShown);
  };

  function handleResponse(responsejson) {
    if (responsejson.status_code) {
      if (responsejson.status_code === STATUS_CODES.INVALID_TOKEN) {
        dispatch(userLogoutClear());
        dispatch(guestUserLogin());
      }
    } else {
      if (responsejson.status === STATUS_CODES.INVALID_TOKEN) {
        dispatch(userLogoutClear());
        dispatch(guestUserLogin());
      }
    }
  }

  const { guestUser, currentUser, isLoading, userToken } = useSelector(
    (state) => state.user
  );

  function GetMetaList(userToken) {
    dispatch(getMetaListApi(userToken)).then((responseJson) => {
      const response = responseJson.payload;
      handleResponse(response);
    });
  }

  function GuestLogin() {
    dispatch(guestUserLogin()).then((responseJson) => {
      const response = responseJson.payload;
      GetMetaList(response.data.token);
    });
  }

  // ======Calling Api for guest user login======
  useEffect(() => {
    if (
      Object.keys(currentUser).length === 0 &&
      Object.keys(guestUser).length === 0
    ) {
      GuestLogin();
    } else {
      GetMetaList(userToken);
    }
  }, [userToken]);

  useEffect(() => {
    userToken && SublyApi.getWebBanners(userToken,BANNERS_WEB_VIEW.HEADER).then(async (responsejson) => {
      // console.log('responsejson', responsejson)
      if (responsejson && responsejson.status === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        await dispatch(userLogoutClear());
        dispatch(guestUserLogin());
      }
      else if (responsejson && responsejson.status_code == STATUS_CODES.SUCCESS) {
        if (responsejson.data && responsejson.data.classified_banners.length > 0) {
          seHeaderBanner(responsejson.data.classified_banners)
        } else {
          seHeaderBanner([])
        }

      } else {
        Toast.fire({
          icon: "error",
          title: responsejson && responsejson.data.message,
        });
      }
    })
  }, [])

  return (
    <section className="header">
      {isLoading === true ? <Loader /> : ""}
      <TopHeader/>
      <Container>
        <div className="headerSection">
          {/* <div className="logoImg">
            <img src={Logo} alt="news-logo" />
          </div> */}
          {location.pathname == "/classifieds" ||
            location.pathname == "/job-types" ? (
            <div className="bannerImg">
              {headerBanner && headerBanner.length > 0 ? headerBanner.map((item, index) => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    );
                  }):""}
              {/* <iframe
                src={process.env.REACT_APP_CLASSIFIED_HEADER_IFRAME_URL}
                style={{
                  width: "100%",
                  height: "95%",
                  margin: "0px",
                  border: "none",
                  scrollBehavior: "none",
                  allowfullscreen: "true",
                }}
              ></iframe> */}
            </div>
          ) : (
            ""
          )}

          {/* <Navbar expand="lg">
            <div className="navItem headerLinks">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ulValue" as="ul">
                  {HeaderData.map((item, index) => (
                    <Nav.Item as="li" key={index} className={`${item.link.match(check) ? "active" : ""}`}>
                      <NavLink className={`nav-link`} to={item.link}>
                        {item.text}
                      </NavLink>
                    </Nav.Item>
                  ))}
                </Nav>
              </Navbar.Collapse>
              <HeaderFeatures />
            </div>
          </Navbar> */}

          <Row>
            <Col xl={8} lg={8} md={12} sm={12} className="columnRight">
              <Row>
                {location.pathname == "/search-list" ?
                  <Col xl={11} lg={11} md={11} sm={12} xs={12} className="columnRight">
                    <div className="navItem1">
                      <Nav className="rowRemove" as="ul">
                        <Slider {...settings}>
                          {HeaderData.map((item, index) => (
                            <Nav.Item as="li" key={index} className={`${item.link.match(check) ? "active" : ""}`}>
                              <NavLink className={`nav-link`} to={item.link}>
                                {item.text}
                              </NavLink>
                            </Nav.Item>
                          ))}
                        </Slider>
                      </Nav>
                    </div>
                  </Col>
                  : <Col xl={11} lg={11} md={11} sm={11} xs={10} className="columnRight">
                    <div className="navItem1">
                      <Nav className="rowRemove" as="ul">
                        <Slider {...settings}>
                          {HeaderData.map((item, index) => (
                            <Nav.Item as="li" key={index} className={`${item.link.match(check) ? "active" : ""}`}>
                              <NavLink className={`nav-link`} to={item.link}>
                                {item.text}
                              </NavLink>
                            </Nav.Item>
                          ))}
                        </Slider>
                      </Nav>
                    </div>
                  </Col>
                }
                {location.pathname != "/search-list" ?
                  <Col xl={1} lg={1} md={1} sm={1} xs={2} className="columnManage">
                    {location.pathname == "/search-list" ?
                      <div className="searchHide">
                        {isShown == false && <FiSearch onClick={() => { navigate("/search-list"); handleClick() }} />}
                      </div>
                      : <div className="searchHide ">
                        <FiSearch onClick={() => { navigate("/search-list"); }} />
                      </div>
                    }
                  </Col>
                  : ''
                }

              </Row>
            </Col>
            <Col xl={4} lg={4} md={12} sm={12}>
              <HeaderFeatures />
            </Col>
          </Row>

          {/* -------DealsHeaderSection-------- */}
          <Row>
            <Col xl={8} lg={9} md={12} sm={12}>
              {location.pathname == "/deals/latest-deals" ||
                location.pathname.match("/deals/companies/company-profile/") ||
                location.pathname == "/deals/products" ||
                location.pathname == "/deals/services" ||
                location.pathname == "/deals/brands" ||
                location.pathname == "/deals/companies" ? (
                <DealsHeader />
              ) : (
                ""
              )}
              {location.pathname == "/classifieds" ||
                location.pathname == "/job-types" ? (
                <ClassifiedCountry />
              ) : (
                ""
              )}
              {location.pathname == "/search-list" ? <SearchTotalCount /> : ""}
            </Col>
            <Col xl={4} lg={3} md={12} sm={12}>
              {location.pathname == "/search-list" ?
                <>
                  {isShown == false && <div className="searchInput"><SearchBar /></div>}
                </>
                : ""
              }
              <div className="searchTab">
                <SearchBar />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}
export default Header;
