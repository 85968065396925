import "./ClassiFieds.css";
import { Row, Nav, Container, Col, Tab } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useSelector } from "react-redux";
import ClassifiedCategoryList from "./ClassifiedCategoryList";
import DOMPurify from 'dompurify';

import {
  setClassfiedType,
  getWantedListApi,
  forSaleListApi,
  setClassifiedFilterName,
} from "../../store/slices/ClassifiedSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader/Loader";
import {
  CLASSIFIED_REFRENCE_TYPE,
  CLASSIFIED_CATEGORY_TYPE,
  BOOK_TYPE,
  PAGINATION_VALUE,
  SEARCH_TYPE,
  COUNT,
  COUNT_REFFRENCE,
  WEB_LIST_TYPE,
  BANNERS_WEB_VIEW,
} from "../../utils/Constants";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toaster";
import { guestUserLogin, userLogoutClear } from "../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import CustomBtn from "../../formComponent/Button/Button";
import SublyApi from "../../helpers/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../assets/images/dots-loading.gif";
import Accordion from "react-bootstrap/Accordion";


function ClassiFieds() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    forSaleTotalCount,
    forSaleWebList,
    wantedTotalCount,
    wantedWebList,
    classifiedFilterValues,
    isLoading,
  } = useSelector((state) => state.classified);
  const { userToken } = useSelector((state) => state.user);
  const bookmarkLoader = useSelector((state) => state.bookmark.isLoading);
  const { bookmarkTotalCount } = useSelector((state) => state.bookmark);
  const [showDefaultList, setShowDefaultList] = useState(1);
  const [offsetForSale, setOffsetForSale] = useState(0);
  const [offsetWanted, setOffsetWanted] = useState(0);
  const [classifiedBanner, seClassifiedBanner] = useState([]);

  const scrollToRef = useRef();

  useEffect(() => {
    userToken && SublyApi.getWebBanners(userToken,BANNERS_WEB_VIEW.CLASSIFIEDS).then(async (responsejson) => {
      // console.log('responsejson', responsejson)
      if (responsejson && responsejson.status === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        await dispatch(userLogoutClear());
        dispatch(guestUserLogin());
      }
      else if (responsejson && responsejson.status_code == STATUS_CODES.SUCCESS) {
        if (responsejson.data && responsejson.data.classified_banners.length > 0) {
          seClassifiedBanner(responsejson.data.classified_banners)
        } else {
          seClassifiedBanner([])
        }

      } else {
        Toast.fire({
          icon: "error",
          title: responsejson && responsejson.data.message,
        });
      }
    })
  }, [])
  //  console.log('classifiedBanner',classifiedBanner)

  //function for loadmore forsale
  const loadmoreForsale = () => {
    if (forSaleWebList.length >= forSaleTotalCount) {
      return false;
    }
    setOffsetForSale(offsetForSale + PAGINATION_VALUE.DEFAULT_LIMIT);
    getForSaleList(true, offsetForSale + PAGINATION_VALUE.DEFAULT_LIMIT);
  };

  //function for loadmore for wanted
  const loadmoreWanted = () => {
    if (wantedWebList.length >= wantedTotalCount) {
      return;
    }
    setOffsetWanted(offsetWanted + PAGINATION_VALUE.DEFAULT_LIMIT);
    getWantedList(true, offsetWanted + PAGINATION_VALUE.DEFAULT_LIMIT);
  };

  // function for classified webList
  const setClassfiedTypeValue = (value) => {
    dispatch(setClassfiedType(value));

    // we will do this point later after client discussion.
    // handleCount(value);
  };

  // function for forSaleList Api
  function getForSaleList(loadmore, offsetValue) {
    if (loadmore === true && forSaleWebList.length >= forSaleTotalCount) {
      return false;
    }
    let forSaleQuery = "";
    if (classifiedFilterValues && classifiedFilterValues.length > 0) {
      forSaleQuery = {
        limit: PAGINATION_VALUE.DEFAULT_LIMIT,
        offset: offsetValue ? offsetValue : offsetForSale,
        type: CLASSIFIED_CATEGORY_TYPE.FORSALE,
        search_by: classifiedFilterValues.search_by
          ? classifiedFilterValues.search_by
          : SEARCH_TYPE.ALL_SOUTH_AFRICA,
        province: classifiedFilterValues.province,
        country: classifiedFilterValues.country,
      };
    } else {
      forSaleQuery = {
        limit: PAGINATION_VALUE.DEFAULT_LIMIT,
        offset: offsetValue ? offsetValue : offsetForSale,
        type: CLASSIFIED_CATEGORY_TYPE.FORSALE,
        search_by: "",
      };
    }

    const data = {
      userToken: userToken,
      whereQuery: forSaleQuery,
      loadmore: loadmore,
    };
    userToken && dispatch(forSaleListApi(data)).then(async (responsejson) => {
      const response = responsejson.payload.response;
      if (response.status_code !== STATUS_CODES.SUCCESS) {
        if (response.status === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
          });
          await dispatch(userLogoutClear());
          dispatch(guestUserLogin());
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
        }
      }
    });
  }

  // function for wantedlist Api
  function getWantedList(loadmore, offsetValue) {
    if (loadmore === true && wantedWebList.length >= wantedTotalCount) {
      return;
    }
    const wantedQuery = {
      limit: PAGINATION_VALUE.DEFAULT_LIMIT,
      offset: offsetValue ? offsetValue : offsetWanted,
      type: CLASSIFIED_CATEGORY_TYPE.WANTED,
      search_by: classifiedFilterValues.search_by
        ? classifiedFilterValues.search_by
        : "",
    };
    const wantedData = {
      userToken: userToken,
      whereQuery: wantedQuery,
      loadmore: loadmore,
    };
    userToken && dispatch(getWantedListApi(wantedData)).then(async (responsejson) => {
      const response = responsejson.payload.response;
      if (response.status_code !== STATUS_CODES.SUCCESS) {
        if (response.status === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
          });
          await dispatch(userLogoutClear());
          dispatch(guestUserLogin());
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
        }
      }
    });
  }

  //---------function for view count for classifieds--------
  async function handleCount(value) {
    let classifiedListData = [];
    if (CLASSIFIED_CATEGORY_TYPE.FORSALE == value) {
      classifiedListData = forSaleWebList;
    } else {
      classifiedListData = wantedWebList;
    }
    classifiedListData &&
      classifiedListData.length > 0 &&
      classifiedListData.map((item, index) => {
        let requestData = new FormData();
        requestData.append("id", item.id);
        requestData.append("type", COUNT.VIEW);
        requestData.append("refrence_type", COUNT_REFFRENCE.CLASSIFIED);
        requestData.append("share_in", 0);
        userToken && SublyApi.updateCount(requestData, userToken).then(async (responsejson) => {
          if (responsejson.status === STATUS_CODES.INVALID_TOKEN) {
            Toast.fire({
              icon: "error",
              title: t("SESSION_EXPIRE"),
            });
            await dispatch(userLogoutClear());
            dispatch(guestUserLogin());
          }
        });
      });
  }

  //function for loadmore
  async function getWebClassifiedLists(loadmore, offsetValue) {
    getForSaleList(loadmore, offsetValue);
    getWantedList(loadmore, offsetValue);
  }

  useEffect(() => {
    dispatch(
      setClassifiedFilterName({
        name: `${t("ALL_CLASSFIED")}`,
        refrenceType: CLASSIFIED_CATEGORY_TYPE.ALL_CLASSIFIED,
        refrenceId: CLASSIFIED_CATEGORY_TYPE.ALL_CLASSIFIED,
        countryId: "",
        city: "",
      })
    );

    setClassfiedTypeValue(CLASSIFIED_CATEGORY_TYPE.FORSALE);
    getWebClassifiedLists(false, PAGINATION_VALUE.DEFAULT_OFFSET);
  }, [bookmarkTotalCount, userToken]);

  const handleClickScroll = () => {
    // Will scroll smoothly to the top of the next section
    setTimeout(() => {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, 500);
  };

  return (
    <div className="main">
      <div className="classifiedData">
        {isLoading === true || bookmarkLoader ? <Loader /> : ""}
        <React.Fragment>
          <Container className="screenOverride">
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className="categoryButton">
                  <div className="accordionResponsive">
                    <Accordion
                      // defaultActiveKey={1}
                      ref={scrollToRef}
                      onSelect={(value) => {
                        setShowDefaultList(value);
                        {
                          value && value === 1
                            ? setClassfiedTypeValue(
                              CLASSIFIED_CATEGORY_TYPE.FORSALE
                            )
                            : setClassfiedTypeValue(
                              CLASSIFIED_CATEGORY_TYPE.WANTED
                            );
                        }
                      }}
                    >
                      <Accordion.Item eventKey={1}>
                        <Accordion.Header onClick={handleClickScroll}>
                          {" "}
                          {t("FOR_SALE")} ({forSaleTotalCount})
                        </Accordion.Header>
                        <Accordion.Body>
                          {showDefaultList == 1 ? (
                            forSaleWebList.length > 0 ? (
                              <InfiniteScroll
                                dataLength={
                                  offsetForSale + PAGINATION_VALUE.DEFAULT_LIMIT
                                }
                                next={
                                  forSaleWebList.length >= forSaleTotalCount
                                    ? null
                                    : loadmoreForsale
                                }
                                style={{ overflow: "inherit" }}
                                hasMore={
                                  forSaleWebList.length >= forSaleTotalCount
                                    ? false
                                    : true
                                }
                                scrollThreshold="2000px"
                                loader={
                                  forSaleWebList.length < forSaleTotalCount && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        style={{ width: "60px" }}
                                        src={Loading}
                                        alt="loading"
                                      />
                                    </div>
                                  )
                                }
                              >
                                <ClassifiedCategoryList
                                  key={0}
                                  forSaleListData={forSaleWebList}
                                  classifiedDataType={
                                    CLASSIFIED_CATEGORY_TYPE.FORSALE
                                  }
                                  webListType={WEB_LIST_TYPE.FORSALE}
                                  bookType={BOOK_TYPE.CLASSIFIED}
                                />
                              </InfiniteScroll>
                            ) : forSaleTotalCount === 0 &&
                              wantedTotalCount === 0 ? (
                              <p className="nodataDisplay">
                                -- {t("NOCLASSIFIED_DISPLAY")} --
                              </p>
                            ) : forSaleTotalCount === 0 ? (
                              <p className="nodataDisplay">
                                -- {t("NOCLASSIFIEDSALES_DISPLAY")} --
                              </p>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey={2}>
                        <Accordion.Header onClick={handleClickScroll}>
                          {t("WANTED")} ({wantedTotalCount})
                        </Accordion.Header>
                        <Accordion.Body>
                          {wantedWebList.length ? (
                            <>
                              <InfiniteScroll
                                dataLength={
                                  offsetWanted + PAGINATION_VALUE.DEFAULT_LIMIT
                                }
                                next={
                                  wantedWebList.length >= wantedTotalCount
                                    ? null
                                    : loadmoreWanted
                                }
                                style={{ overflow: "inherit" }}
                                hasMore={
                                  wantedWebList.length >= wantedTotalCount
                                    ? false
                                    : true
                                }
                                scrollThreshold="2000px"
                                loader={
                                  wantedWebList.length < wantedTotalCount && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        style={{ width: "60px" }}
                                        src={Loading}
                                        alt="loading"
                                      />
                                    </div>
                                  )
                                }
                              >
                                <ClassifiedCategoryList
                                  key={1}
                                  forSaleListData={wantedWebList}
                                  classifiedDataType={
                                    CLASSIFIED_CATEGORY_TYPE.WANTED
                                  }
                                  webListType={WEB_LIST_TYPE.WANTED}
                                  bookType={BOOK_TYPE.CLASSIFIED}
                                />
                              </InfiniteScroll>
                            </>
                          ) : forSaleTotalCount === 0 &&
                            wantedTotalCount === 0 ? (
                            <p className="nodataDisplay">
                              -- {t("NOCLASSIFIED_DISPLAY")} --
                            </p>
                          ) : wantedTotalCount === 0 ? (
                            <p className="nodataDisplay">
                              -- {t("NOCLASSIFIEDWANTED_DISPLAY")} --
                            </p>
                          ) : (
                            ""
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <div className="advertisment">
                  {classifiedBanner && classifiedBanner.length > 0 ? classifiedBanner.map((item, index) => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    );
                  }):""}
                  {/* <h4 className="displayNoText">{t("NO_BANNER_DATA")}</h4> */}
                </div>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      </div>
    </div>
  );
}
export default ClassiFieds;
