import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./LatestDeals.css";
import "../../../assets/styles/Common.css";
import LatestDealList from "../LatestDealList/LatestDealList";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../utils/Loader/Loader";
import { useTranslation } from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';

function LatestDeals() {
  //set language
  const { t } = useTranslation();
  const { latestDeals, isLoading } = useSelector((state) => state.deal);
  const [eventKeyValue, setEventKeyValue] = useState((latestDeals && latestDeals.length > 0) ? latestDeals[0].id : null);
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const scrollToRef = useRef();
 
  useEffect(()=>{
    setEventKeyValue((latestDeals && latestDeals.length > 0) ? latestDeals[0].id : null)
  },[latestDeals])
  window.addEventListener('resize', function (event) {
    setScreenWidth(window.innerWidth)
  })

  const handleClickScroll = () => {
    // Will scroll smoothly to the top of the next section
    setTimeout(() => {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, 500);
  };

  return (
    <div className="dealContainer">
      {isLoading === true ? (
        <Loader />
      ) : ""}
      {eventKeyValue && latestDeals ?
        <Container className="screenOverride">
          {latestDeals.length > 0 ?
            screenWidth > 991 ?
              < Tab.Container id="left-tabs-example" defaultActiveKey={eventKeyValue}>
                <Row>
                  <Col lg={6} sm={12}>
                    <Nav
                      variant="pills"
                      className="flex-column addTabs stickyClass"
                      onSelect={(value) => {
                        setEventKeyValue(value);
                      }}
                    >
                      <Nav.Item onClick={handleClickScroll}>
                        {latestDeals.length > 0
                          ? latestDeals.map((item, index) => (
                            <Nav.Link key={item.id} eventKey={item.id} >
                              {item.name} ({item.deal_count})
                              <MdKeyboardArrowRight />
                            </Nav.Link>

                          ))
                          : ""}
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Tab.Content ref={scrollToRef}>
                      <Tab.Pane eventKey={eventKeyValue ? eventKeyValue : ""}>
                        <LatestDealList
                          eventKeyValue={eventKeyValue}
                          dealList={latestDeals}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              : <div className="accordionResponsive">
                <Accordion defaultActiveKey={eventKeyValue} onSelect={(value) => { value && setEventKeyValue(value) }}>
                  {latestDeals.length > 0
                    ? latestDeals.map((item, index) => (
                      <Accordion.Item eventKey={item.id} key={item.id}>
                        <Accordion.Header onClick={handleClickScroll}> {item.name} ({item.deal_count})</Accordion.Header>
                        {item.id === eventKeyValue &&
                          <Accordion.Body ref={scrollToRef}>
                            <LatestDealList
                              eventKeyValue={eventKeyValue}
                              dealList={latestDeals}
                            />
                          </Accordion.Body>
                        }
                      </Accordion.Item>
                    ))
                    : ""}
                </Accordion>
              </div>
            :
            <h4 className="displayNoText">{t("NO_DEAL")}</h4>}
        </Container> : ""}
    </div>
  );
}
export default LatestDeals;
