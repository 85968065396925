import { BsWhatsapp } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import CustomPhoneInput from "./CustomPhoneInput";

//--------Create a email input field for profile component----------
function WatsappInput(props) {

    return (

        <div className="phoneInputSet watsappInput">
            <BsWhatsapp />
            <div className="phoneCountry">
                <div className="customPhoneDropDown">
                    <p>{props.countryCodeWatsapp.toUpperCase()}</p>
                    <span>+{props.dialCodeWatsapp.toString()}</span>
                </div>
            </div>
            <PhoneInput

                country={"za"}
                value={props.dialCodeWatsapp.toString() +
                    props.watsappNo.toString()}
                onChange={(value, country) => {
                    let dialCode = country.dialCode;
                    let phone = value.slice(
                        dialCode.length,
                        value.length
                    );
                    props.setCountryCodeWatsapp(country.countryCode)
                    props.setDialCodeWatsapp(dialCode);
                    props.setWatsappNo(phone);

                }}
                countryCodeEditable={false}
                copyNumbersOnly={true}
            />
            <div className="customContact">
                <CustomPhoneInput setPhoneNoError={props.setPhoneNoError} customPhoneName={props.customPhoneName} register={props.register} customPhoneNo={props.customPhoneNo}
                    setCustomPhoneNo={props.setCustomPhoneNo} />
            </div>
        </div>

    );
}
export default WatsappInput;