import styles from "./CompanyData.module.css";
import React from "react";
import { useEffect, useState } from "react";
import { STATUS_CODES } from "../../utils/StatusCode";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../utils/Loader/Loader";
import { Toast } from "../../utils/Toaster";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import CustomBtn from "../../formComponent/Button/Button";
import { useTranslation } from "react-i18next";
import { PAGINATION_VALUE } from "../../utils/Constants";
import CompanyDataModule from "./CompanyDataModule";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from "../../assets/images/dots-loading.gif"
import { guestUserLogin, userLogoutClear } from "../../store/slices/UserSlice";

//  -------function for display company list of products ,services and brands------
function CompanyData(props) {
  const referenceId = props.referenceId;
  const refrenceType = props.refrenceType;

  const { t, i18n } = useTranslation();
  const [companyList, setcompanyList] = useState("");
  const [companyListValue, setCompanyListValue] = useState([]);
  const { userToken } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [limit, setLimit] = useState(PAGINATION_VALUE.DEFAULT_LIMIT);
  const [offset, setOffset] = useState(PAGINATION_VALUE.DEFAULT_OFFSET);
  const dispatch = useDispatch();

  // --------function for get company details----------

  async function loadmoreData() {
    if (companyListValue.length < companyList.total_count) {
      const newOffset = offset + PAGINATION_VALUE.DEFAULT_LIMIT
      await userToken && companyListHandle(true, newOffset);
      await userToken && setOffset(newOffset);
    } else {
      return false;
    }
  }

  async function companyListHandle(loadmore, offsetValue) {
    if (loadmore === true && companyListValue.length >= companyList.total_count) {
      return false;
    }
    const companyValue = {
      refrence_id: referenceId,
      refrence_type: refrenceType,
      limit: PAGINATION_VALUE.DEFAULT_LIMIT,
      offset: offsetValue,
    };
    // setLoader(true);
    const details = userToken && await SublyApi.getCompanyList(userToken, companyValue);

    if (details &&  details.status_code == STATUS_CODES.SUCCESS) {
      if (loadmore === true) {
        const list = [...companyListValue]
        const finalList = list.concat(details.data.list)
        await setCompanyListValue(() => finalList);
      }
      else {
        setCompanyListValue(details.data.list);
      }
      setcompanyList(details.data);

      // setLoader(false);
    } else {
      if (details && details.status === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
        });
        await dispatch(userLogoutClear());
        dispatch(guestUserLogin());
      } else {
        Toast.fire({
          icon: "error",
          title: details && details.data.message,
        });
      }
      
    }
  }
  useEffect(() => {
    companyListHandle(false, PAGINATION_VALUE.DEFAULT_OFFSET);
  }, [userToken]);
  useEffect(() => {
    companyListHandle(false, PAGINATION_VALUE.DEFAULT_OFFSET);
  }, [referenceId, refrenceType]);

  return (
    <>
      {companyList ? (
        <div>
          {loader ? (
            <div className="loader">
              <Loader />
            </div>
          ) : null}
          <div className={styles.products}>
            <h2>
              {companyList.detail.name} -{" "}
              <span>{companyList.total_count} Results</span>
            </h2>
          </div>
          {companyListValue.length > 0
            ?
            (
              <InfiniteScroll
                dataLength={offset + PAGINATION_VALUE.DEFAULT_LIMIT}
                next={companyListValue.length < companyList.total_count ? loadmoreData : null}
                style={{ overflow: "inherit" }}
                hasMore={companyListValue.length >= companyList.total_count ? false : true}
                scrollThreshold="1100px"
                loader={companyListValue.length < companyList.total_count &&
                  <div style={{ display: "flex", justifyContent: "center", marginTop: "14px" }}>
                    <img style={{ width: "60px" }} src={Loading} alt="loading" />
                  </div>}
              >

                {
                  companyListValue.map((item, index) => (
                    <CompanyDataModule companyListValue={item} key={index} />
                  ))
                }
              </InfiniteScroll>
            )
            : ""}
          {/* {companyListValue.length >= companyList.total_count ? (
            " "
          ) : (
            <CustomBtn
              children={t("LOAD_MORE")}
              type={"button"}
              onClick={() => loadmore()}
            />
          )} */}
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "11px" }}>
          <img style={{ width: "60px" }} src={Loading} alt="loading" />
        </div>
      )}
    </>
  );
}
export default CompanyData;
