import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import SublyApi from '../../helpers/Api'
import { STATUS_CODES } from "../../utils/StatusCode";

const initialState = {
  allMetaList: {},
  guestUser: {},
  currentUser: {},
  sessionExpire: "",
  isLoading: false,
  userToken: null,
  success: false,
  message: false,
  error: null,
}

// Thunk for get meta list
export const getMetaListApi = createAsyncThunk(
  "user/getMetaListApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.getClassiFiedMeta(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.loginProcess(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for guest user
export const guestUserLogin = createAsyncThunk(
  "user/guestUserLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.guestUserLogin();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for social login
export const isSocialLogin = createAsyncThunk(
  "user/isSocialLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.checkSocialLogin(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for Social Signup login
export const socialSignup = createAsyncThunk(
  "user/socialSignup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.socialSignup(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


// Thunk for user profile detail get
export const userDetails = createAsyncThunk(
  "user/userDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.userProfile(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user profile update
export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.updateProfile(data.requestData, data.userToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user logout
export const userLogout = createAsyncThunk(
  "user/logout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.userLogout(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// manage user login session 
const userSessionLogin = (state, response) => {
  if (response.status_code === STATUS_CODES.SUCCESS) {
    state.currentUser = response.data;
    state.guestUser = {};
    state.userToken = response.data.token;
    state.success = true;
  } else {
    state.currentUser = {};
    state.userToken = null;
    state.success = false;
  }
  state.isLoading = false
};

// manage user session expire
export const isUserSessionExpire = (state, response) => {
  if (response.status_code === STATUS_CODES.INVALID_TOKEN) {
    state.sessionExpire = response.data.message;
    state.currentUser = {};
    state.guestUser = {};
    state.userToken = null;
  }
  state.isLoading = false
};

// Thunk for get linked user info
export const verifyLinkdinLogin = createAsyncThunk(
  "user/isLinkedInLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.verifyLinkdinLogin(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.currentUser = {};
      state.isLoading = false;
      state.guestUser = {};
      state.userToken = null;
    }
  },
  extraReducers: (builder) => {
    // guest user login
    builder.addCase(guestUserLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.guestUser = response.data;
        state.currentUser = {};
        state.userToken = response.data.token;
        state.success = true;
      } else {
        state.guestUser = {};
        state.userToken = null;
        state.success = false;
      }
    })

    // user login
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      userSessionLogin(state, response);
    })
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    // user logout
    builder.addCase(userLogout.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(userLogout.fulfilled, (state, action) => {
      // const response = action.payload;
      state.isLoading = false
      state.currentUser = {};
      state.guestUser = {};
      state.userToken = null;
    })
   

    // social signup
    builder.addCase(socialSignup.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(socialSignup.fulfilled, (state, action) => {
      const response = action.payload;
      userSessionLogin(state, response);
    })
    builder.addCase(socialSignup.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    // check social login
    builder.addCase(isSocialLogin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(isSocialLogin.fulfilled, (state, action) => {
      const response = action.payload;
      userSessionLogin(state, response);
    })
    builder.addCase(isSocialLogin.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    // check get user detail
    builder.addCase(userDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(userDetails.fulfilled, (state, action) => {
      const response = action.payload;
      isUserSessionExpire(state, response);
    })
    builder.addCase(userDetails.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    // check update profile 
    builder.addCase(updateProfile.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const response = action.payload;
      isUserSessionExpire(state, response);
    })
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    // check get meta list
    builder.addCase(getMetaListApi.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMetaListApi.fulfilled, (state, action) => {
      const response = action.payload;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        state.allMetaList = response.data;
        state.success = true;
      } else {
        state.allMetaList = {};
        state.success = false;
      }
      state.isLoading = false
    })
    builder.addCase(getMetaListApi.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    // user verify user login
    builder.addCase(verifyLinkdinLogin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(verifyLinkdinLogin.fulfilled, (state, action) => {
      const response = action.payload;
    })
    builder.addCase(verifyLinkdinLogin.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

  },
})

export const {userLogoutClear} = userSlice.actions
export default userSlice.reducer