import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

//--------Create a name input field component----------
function CustomPhoneInput({ register, setCustomPhoneNo, customPhoneNo, customPhoneName, setPhoneNoError }) {
  //set language
  const { t } = useTranslation();

  function handleChange(e) {
    setPhoneNoError('');
    let inputValue = e.target.value;
    let phone = inputValue.replace(/\D/g, ''); // filter only numbers

    if (phone.length == 11) return false; // only 10 digit

    if (phone.length > 3 && phone.length < 10) { // run when digit less then 10
      phone = phone.replace(/.{3}(?!$)/g, '$&-'); // add dash after every three letter
    } else {
      phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    setCustomPhoneNo(phone)
  }

  return (
    <>
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Control
          className='phoneInputId'
          type="text"
          placeholder={customPhoneName + " " + t("NUMBER")}
          {...register(customPhoneName, {
            // required: {
            //   value: true,
            //   message: `${t("INCOMPLETE")}`,
            // },
            // minLength: {
            //   value: 12,
            //   message: `${customPhoneName} number must be 10 digits` // JS only: <p>error message</p> TS only support string
            // }

            // pattern: {
            //   value: /^(?![\s.]+$)[a-zA-Z\s.]*$/,
            //   message: `${t("INVALID_NAME")}`,
            // },
          })}
          onChange={(e) => handleChange(e)}
          value={customPhoneNo}
        />
      </Form.Group>

    </>
  );
}
export default CustomPhoneInput;