import 'bootstrap/dist/css/bootstrap.min.css';
import Routers from './routes/Routes';

function App() {
  return (
    <Routers />
  );
}

export default App;
