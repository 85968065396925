import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { COUNT, COUNT_REFFRENCE } from "../../utils/Constants";

const initialState = {
  yourAdvertTotalCount: 0,
  yourAdvertWebList: [],
  forSaleTotalCount: 0,
  forSaleWebList: [],
  wantedTotalCount: 0,
  wantedWebList: [],
  jobOfferTotalCount: 0,
  jobOfferWebList: [],
  jobSeekerTotalCount: 0,
  jobSeekerWebList: [],
  classifiedType: 4,
  isLoading: false,
  classifiedTotalCount: 0,
  classifiedFilterValues: {
    name: "All South Africa",
    refrenceType: "1",
    refrenceId: "all",
    countryId: "0",
    city: "",
  },
};

// Thunk for your advert list
export const yourAdvertListApi = createAsyncThunk(
  "classified/yourAdvertListApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = data.userToken && await SublyApi.getWebClassiFiedList(
        data.userToken,
        data.whereQuery
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for for sale classfied list
export const forSaleListApi = createAsyncThunk(
  "classified/forSaleListApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = data.userToken && await SublyApi.getWebClassiFiedList(
        data.userToken,
        data.whereQuery
      );
      return {
        response: response,
        loadmore: data.loadmore,
        userToken: data.userToken,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for for wanted classfied list
export const getWantedListApi = createAsyncThunk(
  "classified/getWantedListApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = data.userToken && await SublyApi.getWebClassiFiedList(
        data.userToken,
        data.whereQuery
      );
      return {
        response: response,
        loadmore: data.loadmore,
        userToken: data.userToken,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for for job classfied list
export const getJobOfferListApi = createAsyncThunk(
  "jobtypes/getJobOfferListApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = data.userToken && await SublyApi.getWebClassiFiedList(
        data.userToken,
        data.whereQuery
      );
      return {
        response: response,
        loadmore: data.loadmore,
        userToken: data.userToken,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for for job seeker classfied list
export const getJobSeekerListApi = createAsyncThunk(
  "jobtypes/getJobSeekerListApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = data.userToken && await SublyApi.getWebClassiFiedList(
        data.userToken,
        data.whereQuery
      );
      return {
        response: response,
        loadmore: data.loadmore,
        userToken: data.userToken,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const classifiedSlice = createSlice({
  name: "classified",

  initialState,
  reducers: {
    setJobOfferTotalCount: (state, action) => {
      state.jobOfferTotalCount = action.payload;
    },
    setJobSeekerTotalCount: (state, action) => {
      state.jobSeekerCount = action.payload;
    },
    setClassfiedType: (state, action) => {
      state.classifiedType = action.payload;
    },
    setClassifiedFilterName: (state, action) => {
      state.classifiedFilterValues = action.payload;
    },
    handleForSaleWebList: (state, action) => {
      state.forSaleWebList = action.payload.forSaleWebList;
    },
    handleWantedWebList: (state, action) => {
      state.wantedWebList = action.payload.wantedWebList;
    },
    handleJobOfferWebList: (state, action) => {
      state.jobOfferWebList = action.payload.jobOfferWebList;
    },
    handleJobSeekerWebList: (state, action) => {
      state.jobSeekerWebList = action.payload.jobSeekerWebList;
    },
  },

  extraReducers: (builder) => {
    //your advert list
    builder.addCase(yourAdvertListApi.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(yourAdvertListApi.fulfilled, (state, action) => {
      const response = action.payload;
      if (response.status_code == STATUS_CODES.SUCCESS) {
        state.yourAdvertTotalCount = response.data.total_count;
        state.yourAdvertWebList = response.data.list;
      } else {
        state.yourAdvertTotalCount = 0;
        state.yourAdvertWebList = [];
      }
      state.isLoading = false;
    });
    builder.addCase(yourAdvertListApi.rejected, (state, action) => {
      state.isLoading = false;
    });

    //Forsale list api
    builder.addCase(forSaleListApi.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(forSaleListApi.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.status_code == STATUS_CODES.SUCCESS) {
        response.data.list.length > 0 &&
          response.data.list.map((item, index) => {
            let requestData = new FormData();
            requestData.append("id", item.id);
            requestData.append("type", COUNT.VIEW);
            requestData.append("refrence_type", COUNT_REFFRENCE.CLASSIFIED);
            requestData.append("share_in", 0);
            SublyApi.updateCount(requestData, action.payload.userToken).then(
              (responsejson) => {
                if (responsejson.status === STATUS_CODES.INVALID_TOKEN) {
                  // Toast.fire({
                  //   icon: "error",
                  //   title: t("SESSION_EXPIRE"),
                  // });
                  // dispatch(userLogout(userToken)).then(() => {
                  //   dispatch(guestUserLogin());
                  //   navigate("/login");
                  // });
                }
              }
            );
          });
        if (action.payload.loadmore == true) {
          state.forSaleWebList = state.forSaleWebList.concat(
            response.data.list
          );
        } else {
          state.forSaleWebList = response.data.list;
        }
        state.forSaleTotalCount = response.data.total_count;
      } else {
        state.forSaleTotalCount = 0;
        state.forSaleWebList = [];
      }
      // state.isLoading = false;
    });
    builder.addCase(forSaleListApi.rejected, (state, action) => {
      // state.isLoading = false;
    });

    // wanted list api
    builder.addCase(getWantedListApi.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(getWantedListApi.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        response.data.list.length > 0 &&
          response.data.list.map((item, index) => {
            let requestData = new FormData();
            requestData.append("id", item.id);
            requestData.append("type", COUNT.VIEW);
            requestData.append("refrence_type", COUNT_REFFRENCE.CLASSIFIED);
            requestData.append("share_in", 0);
            SublyApi.updateCount(requestData, action.payload.userToken).then(
              (responsejson) => {
                if (responsejson.status === STATUS_CODES.INVALID_TOKEN) {
                  // Toast.fire({
                  //   icon: "error",
                  //   title: t("SESSION_EXPIRE"),
                  // });
                  // dispatch(userLogout(userToken)).then(() => {
                  //   dispatch(guestUserLogin());
                  //   navigate("/login");
                  // });
                }
              }
            );
          });
        if (action.payload.loadmore == true) {
          state.wantedWebList = state.wantedWebList.concat(response.data.list);
        } else {
          state.wantedWebList = response.data.list;
        }
        state.wantedTotalCount = response.data.total_count;
        state.success = true;
      } else {
        state.wantedTotalCount = 0;
        state.wantedWebList = [];
        state.success = false;
      }
      // state.isLoading = false;
    });
    builder.addCase(getWantedListApi.rejected, (state, action) => {
      // state.isLoading = false;
    });

    // JobOffer list api
    builder.addCase(getJobOfferListApi.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(getJobOfferListApi.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        response.data.list.length > 0 &&
          response.data.list.map((item, index) => {
            let requestData = new FormData();
            requestData.append("id", item.id);
            requestData.append("type", COUNT.VIEW);
            requestData.append("refrence_type", COUNT_REFFRENCE.CLASSIFIED);
            requestData.append("share_in", 0);
            SublyApi.updateCount(requestData, action.payload.userToken).then(
              (responsejson) => {
                if (responsejson.status === STATUS_CODES.INVALID_TOKEN) {
                  // Toast.fire({
                  //   icon: "error",
                  //   title: t("SESSION_EXPIRE"),
                  // });
                  // dispatch(userLogout(userToken)).then(() => {
                  //   dispatch(guestUserLogin());
                  //   navigate("/login");
                  // });
                }
              }
            );
          });
        if (action.payload.loadmore == true) {
          state.jobOfferWebList = state.jobOfferWebList.concat(
            response.data.list
          );
        } else {
          state.jobOfferWebList = response.data.list;
        }
        state.jobOfferTotalCount = response.data.total_count;
        state.success = true;
      } else {
        state.jobOfferTotalCount = 0;
        state.jobOfferWebList = [];
        state.success = false;
      }
      // state.isLoading = false;
    });
    builder.addCase(getJobOfferListApi.rejected, (state, action) => {
      // state.isLoading = false;
    });

    // JobSeeker list api
    builder.addCase(getJobSeekerListApi.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(getJobSeekerListApi.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        response.data.list.length > 0 &&
          response.data.list.map((item, index) => {
            let requestData = new FormData();
            requestData.append("id", item.id);
            requestData.append("type", COUNT.VIEW);
            requestData.append("refrence_type", COUNT_REFFRENCE.CLASSIFIED);
            requestData.append("share_in", 0);
            SublyApi.updateCount(requestData, action.payload.userToken).then(
              (responsejson) => {
                if (responsejson.status === STATUS_CODES.INVALID_TOKEN) {
                  // Toast.fire({
                  //   icon: "error",
                  //   title: t("SESSION_EXPIRE"),
                  // });
                  // dispatch(userLogout(userToken)).then(() => {
                  //   dispatch(guestUserLogin());
                  //   navigate("/login");
                  // });
                }
              }
            );
          });
        if (action.payload.loadmore == true) {
          state.jobSeekerWebList = state.jobSeekerWebList.concat(
            response.data.list
          );
        } else {
          state.jobSeekerWebList = response.data.list;
        }
        state.jobSeekerTotalCount = response.data.total_count;
        state.success = true;
      } else {
        state.jobSeekerTotalCount = 0;
        state.jobSeekerWebList = [];
        state.success = false;
      }
      // state.isLoading = false;
    });
    builder.addCase(getJobSeekerListApi.rejected, (state, action) => {
      // state.isLoading = false;
    });
  },
});
export const {
  setClassifiedFilterName,
  setClassfiedType,
  setJobOfferTotalCount,
  setJobSeekerTotalCount,
  handleForSaleWebList,
  handleJobOfferWebList,
  handleJobSeekerWebList,
  handleWantedWebList,
} = classifiedSlice.actions;

export default classifiedSlice.reducer;
