import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import styles from './CompanyData.module.css';
import { MdKeyboardArrowRight } from "react-icons/md";
import { useState, useRef, useEffect } from "react";
import CompanyData from "./CompanyData";
import CompanyOrderType from "./CompaniesOrderType";
import { useLocation } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import { REFERENCE_TYPE } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';
import { useSelector } from "react-redux";


// -----------function for display companies of products,services and brands------------
function CompanyList({ companyList, refrenceType }) {
    //set language
    const { t } = useTranslation();
    const location = useLocation();
    const [eventKeyValue, setEventKeyValue] = useState((companyList && companyList.length > 0) ? companyList[0].id : null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const scrollToRef = useRef();
    const { allDeals } = useSelector((state) => state.deal);

    useEffect(() => {
        setEventKeyValue((companyList && companyList.length > 0) ? companyList[0].id : null)
    }, [companyList])

    window.addEventListener('resize', function (event) {
        setScreenWidth(window.innerWidth)
    })

    const handleClickScroll = () => {
        // Will scroll smoothly to the top of the next section
        setTimeout(() => {
            scrollToRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "nearest",
            });
        }, 500);
    };

    return (
        <>
            {eventKeyValue && companyList ?
                <div className={styles.productModule}>
                    {companyList.length > 0 ?
                        <Container className="screenOverride">
                            {screenWidth > 991
                                ?
                                <Tab.Container id="left-tabs-example" defaultActiveKey={eventKeyValue} >
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Nav variant="pills"
                                                className="flex-column addTabs stickyClass"
                                                onSelect={(value) => {
                                                    setEventKeyValue(value);
                                                }}>
                                                <Nav.Item onClick={handleClickScroll}>
                                                    {companyList.length > 0
                                                        ? companyList.map((item, index) => (
                                                            <Nav.Link key={item.id} eventKey={item.id}>
                                                                {item.name} {(item.company_count !== 'company_order') ? '(' + item.company_count + ')' : ""}
                                                                <MdKeyboardArrowRight />
                                                            </Nav.Link>
                                                        ))
                                                        : ""}
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col lg={6} sm={12} ref={scrollToRef}>
                                            <Tab.Content style={{ position: "sticky", top: "10px" }}>
                                                <Tab.Pane eventKey={eventKeyValue ? eventKeyValue : ""}>
                                                    {location.pathname == "/deals/products" ||
                                                        location.pathname == "/deals/services" || location.pathname == "/deals/brands" ? (
                                                        <CompanyData
                                                            referenceId={eventKeyValue}
                                                            refrenceType={refrenceType}
                                                        />
                                                    ) : (
                                                        <CompanyOrderType companyList={eventKeyValue} />
                                                    )}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                                :
                                <div className="accordionResponsive" >
                                    <Accordion
                                        defaultActiveKey={eventKeyValue} onSelect={(value) => {
                                            value && setEventKeyValue(value);
                                        }}>
                                        {companyList.length > 0
                                            ? companyList.map((item, index) => (
                                                <Accordion.Item eventKey={item.id} key={item.id}>
                                                    <Accordion.Header
                                                        onClick={handleClickScroll}
                                                    >
                                                        {item.name} {(item.company_count !== 'company_order') ? '(' + item.company_count + ')' : ""}
                                                    </Accordion.Header>
                                                    {
                                                        item.id === eventKeyValue &&
                                                        <Accordion.Body ref={scrollToRef}
                                                        >
                                                            {location.pathname == "/deals/products" ||
                                                                location.pathname == "/deals/services" || location.pathname == "/deals/brands" ? (
                                                                <CompanyData
                                                                    referenceId={eventKeyValue ? eventKeyValue : ""}
                                                                    refrenceType={refrenceType}
                                                                />
                                                            ) : (
                                                                <CompanyOrderType companyList={eventKeyValue} />
                                                            )}
                                                        </Accordion.Body>
                                                    }
                                                </Accordion.Item>
                                            ))
                                            : ""}
                                    </Accordion>
                                </div>
                            }
                        </Container>
                        : <>
                            {refrenceType == REFERENCE_TYPE.PRODUCTS && <h4>{t("NO_PRODUCT")}</h4>}
                            {refrenceType == REFERENCE_TYPE.SERVICES && <h4>{t("NO_SERVICE")}</h4>}
                            {refrenceType == REFERENCE_TYPE.BRANDS && <h4>{t("NO_BRAND")}</h4>}
                        </>

                    }
                </div> : ""}
        </>
    );
}
export default CompanyList;