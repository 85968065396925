import React, { useEffect } from "react";
import { SOCIAL_TYPE } from "../../utils/Constants";
import { STATUS_CODES } from "../../utils/StatusCode";
import { isSocialLogin, socialSignup,verifyLinkdinLogin } from "../../store/slices/UserSlice";
import { Toast } from "../../utils/Toaster";
import { useDispatch,useSelector } from "react-redux";
import { useSearchParams,useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";

function LinkedInVerification() {
  const { isLoading } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [queryParameters] = useSearchParams()

  let code = queryParameters.get("code")

  async function getUserDetail(codeData) {
    let requestData = new FormData();
    requestData.append('code', codeData.trim());
    // called get linkdin user info api by passing code token
    await dispatch(verifyLinkdinLogin(requestData)).then(async (responsejson) => {  
      const response = responsejson.payload;
      if (response.status_code === STATUS_CODES.SUCCESS) {
        isSocialLoginMethod(response.data);
      } else if (response.status_code === 400) {
        Toast.fire({
          icon: "error",
          title: response.data.message,
        });
      }
    });
  }
  useEffect(() => {
   let res = getUserDetail(code);
  }, []);

  const isSocialLoginMethod = async (response) => {
    let userData = response;
    if (userData) {
      let requestData = new FormData();
      requestData.append("name", userData.firstName +' '+userData.lastName);
      requestData.append("social_id", userData.id);
      requestData.append("social_type", SOCIAL_TYPE.LINKEDIN);
      requestData.append("social_key", userData.id);
      requestData.append("email", userData.email);
      requestData.append("profile_url", userData.profileImageURL);
      await dispatch(isSocialLogin(requestData)).then(async (responsejson) => {
        const response = responsejson.payload;
        if (response.status_code === STATUS_CODES.SUCCESS) {
          Toast.fire({
            icon: "success",
            title: response.message,
          });
          navigate("/deals/latest-deals");
        } else if (
          response.data?.status_code == STATUS_CODES.SOCIAL_USER_NOT_FOUND
        ) {
          await dispatch(socialSignup(requestData)).then((signresponsejson) => {
            const response = signresponsejson.payload;
            if (response.status_code === STATUS_CODES.SUCCESS) {
              Toast.fire({
                icon: "success",
                title: response.message,
              });
              navigate("/deals/latest-deals");
            }
            // window.close();
          });
        } else {
          Toast.fire({
            icon: "Error",
            title: response.message,
          });
        }
      });
    }
  };
  return (
    <>
      {isLoading ? <Loader /> : ""}
      </>
  );
}

export default LinkedInVerification;
