import { MdPhonelinkRing } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import CustomPhoneInput from "./CustomPhoneInput";

//--------Create a contact input field for profile component----------
function ContactInput(props) {
    return (
        <div className="phoneInputSet">
            <MdPhonelinkRing />
            <div className="phoneCountry">
                <div className="customPhoneDropDown">
                    <p>{props.country.toUpperCase()} </p>
                    <span>+{props.dialCode.toString()}</span>
                </div>
            </div>
            <PhoneInput
                country={"za"}
                value={props.dialCode.toString() +
                    props.phone.toString()}
                onChange={(value, country) => {
                    let dialCode = country.dialCode;
                    let phone = value.slice(
                        dialCode.length,
                        value.length
                    );
                    props.country1(country.countryCode)
                    props.dialCode1(dialCode);
                    props.phone1(phone);

                }}
                countryCodeEditable={false}
                copyNumbersOnly={true}
            />
            <div className="customContact">
                <CustomPhoneInput setPhoneNoError={props.setPhoneNoError} customPhoneName={props.customPhoneName} register={props.register} customPhoneNo={props.customPhoneNo}
                    setCustomPhoneNo={props.setCustomPhoneNo} />
            </div>
        </div>

    );
}
export default ContactInput;