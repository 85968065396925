import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CompanyList from "../CompanyList";
import { REFERENCE_TYPE } from "../../../utils/Constants";

// -----------function for display services------------
function Services() {
    const { userToken } = useSelector((state) => state.user);
    const { allDeals } = useSelector((state) => state.deal);
    const [companyList, setCompanyList] = useState(allDeals.service_company_count_list);
    const refrenceType = REFERENCE_TYPE.SERVICES;

    useEffect(()=>{
        setCompanyList(allDeals.service_company_count_list)
    },[allDeals])
    
    return (
        <>
            <CompanyList companyList={companyList} refrenceType={refrenceType} />
        </>
    );
}
export default Services;