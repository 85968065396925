import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate,useSearchParams } from "react-router-dom";
// import Footer from "../appComponents/Footer/Footer";
import Footer from "../appComponents/Footernew/Footer"
import Header from "../appComponents/Header/Header";
import ForgotPassword from "../authComponents/ForgotPassword/ForgotPassword";
import EmailVarify from "../authComponents/EmailVarification/EmailVarify";
import Login from "../authComponents/Login/Login";
import LoginForm from "../authComponents/Login/LoginForm/LoginForm";
import PasswordSent from "../authComponents/PasswordSent/PasswordSent";
import SignupForm from "../authComponents/SignupForm/SignupForm";
import LatestDeals from "../appComponents/DealModule/LatestDeals/LatestDeals";
import Profile from "../appComponents/Profile/Profile";
import { useSelector } from "react-redux";
import ViewCompanyProfile from "../appComponents/ViewCompanyProfile/ViewCompanyProfile";
import ClassiFieds from "../appComponents/ClassiFieds/ClassiFieds";
import PageNotFound from "../appComponents/PageNotFound/PageNotFound";
import JobTypes from "../appComponents/ClassiFieds/JobTypes";
import YourAdd from "../appComponents/YourAdd/YourAdd";
import PostAdvert from "../appComponents/PostAdvert/PostAdvert";
import Products from "../appComponents/DealTypesModule/Products/Products";
import Services from "../appComponents/DealTypesModule/Services/Services";
import Brands from "../appComponents/DealTypesModule/Brands/Brands";
import Companies from "../appComponents/DealTypesModule/Companies/Companies";
import LoginAlertModel from "../authComponents/LoginAlertModel/LoginAlertModel";
import Bookmarks from "../appComponents/Bookmarks/Bookmarks";
import SearchList from "../appComponents/Search/SearchList";
import LinkedInVerification from "../authComponents/CommonSocial/LinkedInVerification";
import Loader from "../utils/Loader/Loader";

let GetCodeLinkedInCallback = () => {
    
    const [queryParameters] = useSearchParams()

    let code = queryParameters.get("code")
   
    window.opener.location.href = window.location.origin+`/verify-linkedin?code=${code}`;
    window.close();
    return <Loader />
}    
//-------Create a component for manage routing--------
function Routers() {
    const { currentUser } = useSelector((state) => state.user);
    return (
        <Router basename={"/"}>
            <Header />
            <Routes>
                <Route exact path="/" element={<Navigate to="/deals/latest-deals" />} />
                <Route exact path="/linkedin" element={<GetCodeLinkedInCallback />} />
                <Route exact path="/verify-linkedin" element={<LinkedInVerification />} />
                <Route exact path="/login" element={Object.keys(currentUser).length == 0 ? <Login /> : <Navigate to="/deals/latest-deals" />} />
                <Route exact path="/login-form" element={Object.keys(currentUser).length == 0 ? <LoginForm /> : <Navigate to="/deals/latest-deals" />} />
                <Route exact path="/sign-up" element={Object.keys(currentUser).length == 0 ? <SignupForm /> : <Navigate to="/deals/latest-deals" />} />
                <Route exact path="/forgot-password" element={Object.keys(currentUser).length == 0 ? <ForgotPassword /> : <Navigate to="/deals/latest-deals" />} />
                <Route exact path="/password-sent" element={Object.keys(currentUser).length == 0 ? <PasswordSent /> : <Navigate to="/deals/latest-deals" />} />
                <Route exact path="/email-varify" element={Object.keys(currentUser).length == 0 ? <EmailVarify /> : <Navigate to="/deals/latest-deals" />} />
                <Route exact path="/deals/latest-deals" element={<LatestDeals />} />
                <Route exact path="/view-profile" element={Object.keys(currentUser).length !== 0 ? (<Profile />) : (<Navigate to="/login" />)} />
                <Route exact path="/deals/companies/company-profile/:id" element={<ViewCompanyProfile />} />
                <Route exact path="/classifieds" element={<ClassiFieds />} />
                <Route exact path="/deals/products" element={<Products />} />
                <Route exact path="/deals/services" element={<Services />} />
                <Route exact path="/deals/brands" element={<Brands />} />
                <Route exact path="/deals/companies" element={<Companies />} />
                <Route exact path="/job-types" element={<JobTypes />} />
                <Route exact path="/post-advert" element={Object.keys(currentUser).length !== 0 ? (<PostAdvert />) : (<LoginAlertModel modalValue={true} />)} />
                <Route exact path="/your-add" element={Object.keys(currentUser).length !== 0 ? (<YourAdd />) : (<LoginAlertModel modalValue={true} />)} />
                <Route exact path="/book-marks" element={Object.keys(currentUser).length !== 0 ? (<Bookmarks />) : (<Navigate to="/login" />)} />
                <Route exact path="/search-list" element={<SearchList />} />
                <Route exact path="*" element={<PageNotFound />} />
            </Routes>
            <Footer />
        </Router>


    )
}
export default Routers;
