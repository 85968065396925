import "./ClassiFieds.css";
import { Button, Row, Nav, Container, Col, Tab } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useSelector } from "react-redux";
import ClassifiedCategoryList from "./ClassifiedCategoryList";
import {
  setClassfiedType,
  getJobOfferListApi,
  getJobSeekerListApi,
  setClassifiedFilterName,
} from "../../store/slices/ClassifiedSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader/Loader";
import {
  CLASSIFIED_REFRENCE_TYPE,
  CLASSIFIED_CATEGORY_TYPE,
  BOOK_TYPE,
  PAGINATION_VALUE,
  SEARCH_TYPE,
  WEB_LIST_TYPE,
  BANNERS_WEB_VIEW,
} from "../../utils/Constants";
import { STATUS_CODES } from "../../utils/StatusCode";
import { Toast } from "../../utils/Toaster";
import { useNavigate } from "react-router-dom";
import { guestUserLogin, userLogoutClear } from "../../store/slices/UserSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../assets/images/dots-loading.gif";
import Accordion from "react-bootstrap/Accordion";
import SublyApi from "../../helpers/Api";

//-------Create a Deals Header component--------
function JobTypes() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    classifiedFilterValues,
    jobOfferTotalCount,
    jobOfferWebList,
    jobSeekerTotalCount,
    jobSeekerWebList,
    isLoading,
  } = useSelector((state) => state.classified);
  const { userToken } = useSelector((state) => state.user);
  const [showDefaultList, setShowDefaultList] = useState(1);
  const bookmarkLoader = useSelector((state) => state.bookmark.isLoading);
  const { bookmarkTotalCount } = useSelector((state) => state.bookmark);
  const [offsetJobOffer, setOffsetJobOffer] = useState(0);
  const [offsetJobSeeker, setOffsetJobSeeker] = useState(0);
  const [classifiedBanner, seClassifiedBanner] = useState([]);

  const scrollToRef = useRef();

  // function for handle loadmore for jobOffer
  const loadmoreJobOffer = () => {
    setTimeout(() => {
      if (jobOfferWebList.length >= jobOfferTotalCount) {
        return false;
      }
      setOffsetJobOffer(offsetJobOffer + PAGINATION_VALUE.DEFAULT_LIMIT);
      getJobOfferList(true, offsetJobOffer + PAGINATION_VALUE.DEFAULT_LIMIT);
    }, 1000);
  };

  // function for handle loadmore for jobSeeker
  const loadmoreJobSeeker = () => {
    setTimeout(() => {
      if (jobSeekerWebList.length >= jobSeekerTotalCount) {
        return false;
      }
      setOffsetJobSeeker(offsetJobSeeker + PAGINATION_VALUE.DEFAULT_LIMIT);
      getJobSeekerList(true, offsetJobSeeker + PAGINATION_VALUE.DEFAULT_LIMIT);
    }, 1000);
  };

  // function for classified webList
  const setClassfiedTypeValue = (value) => {
    dispatch(setClassfiedType(value));
  };

  useEffect(() => {
    userToken && SublyApi.getWebBanners(userToken,BANNERS_WEB_VIEW.CLASSIFIEDS).then(async (responsejson) => {
      // console.log('responsejson', responsejson)
      if (responsejson && responsejson.status === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        await dispatch(userLogoutClear());
        dispatch(guestUserLogin());
      }
      else if (responsejson && responsejson.status_code == STATUS_CODES.SUCCESS) {
        if (responsejson.data && responsejson.data.classified_banners.length > 0) {
          seClassifiedBanner(responsejson.data.classified_banners)
        } else {
          seClassifiedBanner([])
        }

      } else {
        Toast.fire({
          icon: "error",
          title: responsejson && responsejson.data.message,
        });
      }
    })
  }, [])

  // function for jobOfferList Api
  function getJobOfferList(loadmore, offsetValue) {
    let jobOfferQuery = "";
    if (classifiedFilterValues && classifiedFilterValues.length > 0) {
      jobOfferQuery = {
        limit: PAGINATION_VALUE.DEFAULT_LIMIT,
        offset: offsetValue ? offsetValue : offsetJobOffer,
        type: CLASSIFIED_CATEGORY_TYPE.JOBOFFER,
        search_by: classifiedFilterValues.search_by
          ? classifiedFilterValues.search_by
          : SEARCH_TYPE.ALL_SOUTH_AFRICA,
        province: classifiedFilterValues.province,
        country: classifiedFilterValues.country,
      };
    } else {
      jobOfferQuery = {
        limit: PAGINATION_VALUE.DEFAULT_LIMIT,
        offset: offsetValue ? offsetValue : offsetJobOffer,
        type: CLASSIFIED_CATEGORY_TYPE.JOBOFFER,
        search_by: "",
      };
    }

    const jobOfferData = {
      userToken: userToken,
      whereQuery: jobOfferQuery,
      loadmore: loadmore,
    };
    userToken && dispatch(getJobOfferListApi(jobOfferData)).then(async (responsejson) => {
      const response = responsejson.payload.response;
      if (response.status_code !== STATUS_CODES.SUCCESS) {
        if (response.status === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
          });
          await dispatch(userLogoutClear());
          dispatch(guestUserLogin());
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
        }
      }
    });
  }

  // function for jobSeekerList Api
  function getJobSeekerList(loadmore, offsetValue) {
    const jobSeekerQuery = {
      limit: PAGINATION_VALUE.DEFAULT_LIMIT,
      offset: offsetValue ? offsetValue : offsetJobSeeker,
      type: CLASSIFIED_CATEGORY_TYPE.JOBSEEKERS,
      search_by: classifiedFilterValues.search_by
        ? classifiedFilterValues.search_by
        : "",
    };
    const jobSeekerData = {
      userToken: userToken,
      whereQuery: jobSeekerQuery,
      loadmore: loadmore,
    };
    userToken && dispatch(getJobSeekerListApi(jobSeekerData)).then(async (responsejson) => {
      const response = responsejson.payload.response;
      if (response.status_code !== STATUS_CODES.SUCCESS) {
        if (response.status === STATUS_CODES.INVALID_TOKEN) {
          Toast.fire({
            icon: "error",
            title: t("SESSION_EXPIRE"),
          });
          await dispatch(userLogoutClear());
          dispatch(guestUserLogin());
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.message,
          });
        }
      }
    });
  }

  // function for loadmoreList
  async function getWebClassifiedLists(loadmore, offsetValue) {
    getJobOfferList(loadmore, offsetValue);
    getJobSeekerList(loadmore, offsetValue);
  }

  // function for show default all south africa list api
  useEffect(() => {
    dispatch(
      setClassifiedFilterName({
        name: `${t("ALL_CLASSFIED")}`,
        refrenceType: CLASSIFIED_CATEGORY_TYPE.ALL_CLASSIFIED,
        refrenceId: CLASSIFIED_CATEGORY_TYPE.ALL_CLASSIFIED,
        countryId: "",
        city: "",
      })
    );
    setClassfiedTypeValue(CLASSIFIED_CATEGORY_TYPE.JOBOFFER);

    getWebClassifiedLists(false, PAGINATION_VALUE.DEFAULT_OFFSET);
  }, [bookmarkTotalCount,userToken]);

  const handleClickScroll = () => {
    // Will scroll smoothly to the top of the next section
    setTimeout(() => {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, 500);
  };

  return (
    <div className="main">
      {isLoading === true || bookmarkLoader ? <Loader /> : ""}
      <React.Fragment>
        <Container className="screenOverride">
          <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="categoryButton">
                <div className="accordionResponsive">
                  <Accordion
                    // defaultActiveKey={1}
                    ref={scrollToRef}
                    onSelect={(value) => {
                      setShowDefaultList(value);
                      {
                        value && value === 1
                          ? setClassfiedTypeValue(
                              CLASSIFIED_CATEGORY_TYPE.JOBOFFER
                            )
                          : setClassfiedTypeValue(
                              CLASSIFIED_CATEGORY_TYPE.JOBSEEKERS
                            );
                      }
                    }}
                  >
                    <Accordion.Item eventKey={1}>
                      <Accordion.Header onClick={handleClickScroll}>
                        {t("JOB_OFFERS")} ({jobOfferTotalCount})
                      </Accordion.Header>
                      <Accordion.Body>
                        {showDefaultList == 1 ? (
                          jobOfferWebList.length > 0 ? (
                            <>
                              <InfiniteScroll
                                dataLength={
                                  offsetJobOffer +
                                  PAGINATION_VALUE.DEFAULT_LIMIT
                                }
                                next={
                                  jobOfferWebList.length < jobOfferTotalCount
                                    ? loadmoreJobOffer
                                    : null
                                }
                                style={{ overflow: "inherit" }}
                                hasMore={
                                  jobOfferWebList.length < jobOfferTotalCount
                                    ? true
                                    : false
                                }
                                scrollThreshold="2000px"
                                loader={
                                  jobOfferWebList.length <
                                    jobOfferTotalCount && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        style={{ width: "60px" }}
                                        src={Loading}
                                        alt="loading"
                                      />
                                    </div>
                                  )
                                }
                              >
                                <ClassifiedCategoryList
                                  key={0}
                                  forSaleListData={jobOfferWebList}
                                  classifiedDataType={
                                    CLASSIFIED_CATEGORY_TYPE.JOBOFFER
                                  }
                                  webListType={WEB_LIST_TYPE.JOBOFFER}
                                  bookType={BOOK_TYPE.CLASSIFIED}
                                />
                              </InfiniteScroll>
                            </>
                          ) : (
                            <p className="nodataDisplay">
                              {/* -- {t("NOCLASSIFIED_DISPLAY")} --{" "} */}
                              --- {t("NOCLASSIFIEDJOB_DISPLAY")} ---
                            </p>
                          )
                        ) : (
                          ""
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2}>
                      <Accordion.Header onClick={handleClickScroll}>
                        {t("JOB_SEEKER")} ({jobSeekerTotalCount})
                      </Accordion.Header>
                      <Accordion.Body>
                        {jobSeekerWebList.length ? (
                          <>
                            <InfiniteScroll
                              dataLength={
                                offsetJobSeeker + PAGINATION_VALUE.DEFAULT_LIMIT
                              }
                              next={
                                jobSeekerWebList.length < jobSeekerTotalCount
                                  ? loadmoreJobSeeker
                                  : null
                              }
                              style={{ overflow: "inherit" }}
                              hasMore={
                                jobSeekerWebList.length < jobSeekerTotalCount
                                  ? true
                                  : false
                              }
                              scrollThreshold="2000px"
                              loader={
                                jobSeekerWebList.length <
                                  jobSeekerTotalCount && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      style={{ width: "60px" }}
                                      src={Loading}
                                      alt="loading"
                                    />
                                  </div>
                                )
                              }
                            >
                              <ClassifiedCategoryList
                                key={1}
                                forSaleListData={jobSeekerWebList}
                                classifiedDataType={
                                  CLASSIFIED_CATEGORY_TYPE.JOBSEEKERS
                                }
                                webListType={WEB_LIST_TYPE.JOBSEEKERS}
                                bookType={BOOK_TYPE.CLASSIFIED}
                              />
                            </InfiniteScroll>
                          </>
                        ) : (
                          <p className="nodataDisplay">
                            {/* -- {t("NOCLASSIFIED_DISPLAY")} --{" "} */}
                            --- {t("NOCLASSIFIEDJOB_DISPLAY")} ---
                          </p>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5}>
              <div className="advertisment">
              {classifiedBanner && classifiedBanner.length > 0 ? classifiedBanner.map((item, index) => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    );
                  }):""}
                {/* <iframe
                  src="https://www.signafrica.com?_dnid=84043&t=1682677168"
                  height="930"
                  scrolling="no"
                ></iframe> */}
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
}
export default JobTypes;
