import { Form } from "react-bootstrap";
import "./SearchBar.css";
import { FiSearch } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  clearSearchData,
  redirectSearchStore,
  searchListApi,
  storeSearchValue,
} from "../../store/slices/SearchSlice";
import { STATUS_CODES } from "../../utils/StatusCode";
import { guestUserLogin, userLogoutClear } from "../../store/slices/UserSlice";
import { Toast } from "../../utils/Toaster";
import { PAGINATION_VALUE } from "../../utils/Constants";

//-------Create a Search component--------
function SearchBar() {
  const [results, setResults] = useState();
  const { userToken } = useSelector((state) => state.user);
  const { redirectSearchValue } = useSelector((state) => state.search);
  //set language
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSearch = async () => {
    let searchData = results ? results : redirectSearchValue;

    if (!searchData) return false;

    userToken && dispatch(storeSearchValue(searchData));
    const searchValues = {
      limit: PAGINATION_VALUE.DEFAULT_LIMIT,
      offset: PAGINATION_VALUE.DEFAULT_OFFSET,
      search: searchData,
    };
    userToken && dispatch(searchListApi({ userToken: userToken, searchValues })).then(
      async (responsejson) => {
        if (
          responsejson.payload.response.status_code === STATUS_CODES.SUCCESS
        ) {
        } else if (
          responsejson.payload.response.data.status_code ===
          STATUS_CODES.BAD_REQUEST
        ) {
          Toast.fire({
            icon: "error",
            title: t(responsejson.payload.response.data.message),
          });
        } else if (responsejson.payload.response.status_code) {
          if (
            responsejson.payload.response.status_code ===
            STATUS_CODES.INVALID_TOKEN
          ) {
            Toast.fire({
              icon: "error",
              title: t("SESSION_EXPIRE"),
            });
            dispatch(userLogoutClear());
            dispatch(guestUserLogin());
          }
        } else {
          if (
            responsejson.payload.response.status === STATUS_CODES.INVALID_TOKEN
          ) {
            Toast.fire({
              icon: "error",
              title: t("SESSION_EXPIRE"),
            });
            dispatch(userLogoutClear());
            dispatch(guestUserLogin());
          }
        }
      }
    );
  };
  const redirectSearch = async () => {
    if (location.pathname != "/search-list") {
      {
        navigate("/search-list");
        // dispatch(storeSearchValue(results));
        dispatch(redirectSearchStore(results));
      }
    } else {
      results
        ? handleSearch()
        : Toast.fire({
            icon: "error",
            title: "Please enter value in search input",
          });
    }
  };
  useEffect(() => {
    if (location.pathname !== "/search-list") {
      setResults("");
    }
  }, [location.pathname,userToken]);
  useEffect(() => {
    if (location.pathname == "/search-list") {
      if (!results) {
        setResults(redirectSearchValue);
        handleSearch();
      }
    }
  }, [userToken]);
  return (
    <div
      className="searchBar"
      onClick={() => {
        if (location.pathname !== "/search-list") {
          // navigate("/search-list");
          dispatch(clearSearchData());
        }
      }}
    >
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Control
          type="search"
          placeholder={t("Search")}
          value={results}
          onChange={(e) => setResults(e.target.value)}
          onKeyUp={(e) => {
            if (e.keyCode == 13) {
              redirectSearch();
            }
          }}
        />
      </Form.Group>
      <FiSearch
        onClick={() => {
          redirectSearch();
        }}
      />
    </div>
  );
}
export default SearchBar;
