export const SOCIAL_TYPE = {
  GOOGLE: "1",
  FACEBOOK: "2",
  APPLE: "3",
  LINKEDIN: "4",
};

export const REFERENCE_TYPE = {
  PRODUCTS: "1",
  SERVICES: "2",
  BRANDS: "3",
};

export const CLASSIFIED_CATEGORY_TYPE = {
  ALL:"all",
  ALL_CLASSIFIED:"all_classified",
  FORSALE: "4",
  WANTED: "5",
  JOBOFFER: "6",
  JOBSEEKERS: "7",
};

export const WEB_LIST_TYPE = {
  FORSALE: "4",
  WANTED: "5",
  JOBOFFER: "6",
  JOBSEEKERS: "7",
  BOOKMARK: "8",
  SEARCH: "9",
};

export const CLASSIFIED_REFRENCE_TYPE = {
  ALL_COUNTRY: 0,
  ALL_SOUTH_AFRICA: 1,
  OUTSIDE_SOUTH_AFRICA: 2,
};

export const COMPANY_REFERENCE_TYPE = {
  PRODUCT_TYPE: "1",
  SERVICE_TYPE: "2",
  DEAL_TYPE: "3",
};

export const BOOK_TYPE = {
  CLASSIFIED: "1",
  NEWS: "2",
  DEAL: "3",
};

export const BOOK_ACTION_TYPE = {
  ADD: "1",
  REMOVE: "0",
  REMOVE_ALL: "2",
};

export const PAGINATION_VALUE = {
  DEFAULT_OFFSET: 0,
  DEFAULT_LIMIT: 5,
};

export const SEARCH_TYPE = {
  ALL_SOUTH_AFRICA: 0,
  PROVINCE: 1,
  OUTSIDE_SOUTH_AFRICA: 2,
};

export const SERACH_REFRENCE_TYPE = {
  CLASSIFIED: 1,
  NEWS: 2,
  DEAL: 3,
  COMPANY: 4,
};

export const COUNT = {
  VIEW: 1,
  SHARES: 2,
  CLICK: 3,
};

export const SHARE_COUNT = {
  SHARE: 0,
  WHATSAPP: 1,
  FACEBOOK: 2,
  TWITTER: 3,
  LINKEDIN: 4,
  INSTRAGRAM: 5,
  YOUTUBE: 6,
};

export const COUNT_REFFRENCE = {
  CLASSIFIED: 1,
  NEWS: 2,
  DEAL: 3,
  COMPANY: 4,
};

export const SERVER_PLATFORM_TYPE = {
  PLATFORM_TYPE: process.env.REACT_APP_PLATFORM_TYPE,
  FOR_PLATFORM: process.env.REACT_APP_FOR_PLATFORM,
};
export const BANNERS_WEB_VIEW = {
  HEADER: 1,
  FOOTER: 2,
  CLASSIFIEDS: 3
};