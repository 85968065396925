import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import {
  BOOK_ACTION_TYPE,
  PAGINATION_VALUE,
  WEB_LIST_TYPE,
} from "../../utils/Constants";
import { Toast } from "../../utils/Toaster";
import {
  addBookmarkApi,
  bookmarkListApi,
  handleBookmarkList,
} from "../../store/slices/BookmarkSlice";
import { guestUserLogin, userLogoutClear } from "../../store/slices/UserSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoginAlertModel from "../../authComponents/LoginAlertModel/LoginAlertModel";
import {
  handleForSaleWebList,
  handleJobOfferWebList,
  handleJobSeekerWebList,
  handleWantedWebList,
} from "../../store/slices/ClassifiedSlice";
import { handleSearchList } from "../../store/slices/SearchSlice";

function AddBookmarks(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [shwoAlertPopup, setShowAlertPopup] = useState(false);
  const { forSaleWebList, wantedWebList, jobOfferWebList, jobSeekerWebList } =
    useSelector((state) => state.classified);
  const { bookmarkList } = useSelector((state) => state.bookmark);
  const { searchList } = useSelector((state) => state.search);

  // =====Here managing add and remove bookmark and calling api=====
  async function handleAddBookmark(id, action) {
    const requestData = new FormData();
    requestData.append("id", id);
    requestData.append("status", action);
    requestData.append("type", props.bookType);
    userToken && dispatch(
      addBookmarkApi({ userToken: userToken, requestData: requestData })
    ).then((response) => {
      if (response.payload.status_code == STATUS_CODES.SUCCESS) {
        if (action == BOOK_ACTION_TYPE.ADD) {
          Toast.fire({
            icon: "success",
            title: response.payload.message,
          });
          const requiredValue = {
            limit: PAGINATION_VALUE.DEFAULT_LIMIT,
            offset: PAGINATION_VALUE.DEFAULT_OFFSET,
          };
          if (props.webListType == WEB_LIST_TYPE.FORSALE) {
            let BookmarksForValue = [...forSaleWebList];
            BookmarksForValue[props.index] = {
              ...BookmarksForValue[props.index],
              is_bookmark: 1,
            };
            dispatch(
              handleForSaleWebList({ forSaleWebList: BookmarksForValue })
            );
          } else if (props.webListType == WEB_LIST_TYPE.WANTED) {
            let BookmarksForValue = [...wantedWebList];
            BookmarksForValue[props.index] = {
              ...BookmarksForValue[props.index],
              is_bookmark: 1,
            };
            dispatch(handleWantedWebList({ wantedWebList: BookmarksForValue }));
          } else if (props.webListType == WEB_LIST_TYPE.JOBOFFER) {
            let BookmarksForValue = [...jobOfferWebList];
            BookmarksForValue[props.index] = {
              ...BookmarksForValue[props.index],
              is_bookmark: 1,
            };
            dispatch(
              handleJobOfferWebList({ jobOfferWebList: BookmarksForValue })
            );
          } else if (props.webListType == WEB_LIST_TYPE.JOBSEEKERS) {
            let BookmarksForValue = [...jobSeekerWebList];
            BookmarksForValue[props.index] = {
              ...BookmarksForValue[props.index],
              is_bookmark: 1,
            };
            dispatch(
              handleJobSeekerWebList({ jobSeekerWebList: BookmarksForValue })
            );
          } else if (props.webListType == WEB_LIST_TYPE.BOOKMARK) {
            let BookmarksForValue = [...bookmarkList];
            BookmarksForValue.splice(props.index, 1);
            dispatch(handleBookmarkList({ bookmarkList: BookmarksForValue }));
          } else if (props.webListType == WEB_LIST_TYPE.SEARCH) {
            let BookmarksForValue = [...searchList];
            BookmarksForValue[props.searchIndex] = {
              ...BookmarksForValue[props.searchIndex],
            };
            let searchDetail = BookmarksForValue[props.searchIndex].detail;
            searchDetail = [{ ...searchDetail[0], is_bookmark: 1 }];
            BookmarksForValue[props.searchIndex] = {
              ...BookmarksForValue[props.searchIndex],
              detail: searchDetail,
            };
            BookmarksForValue = [...BookmarksForValue];
            dispatch(handleSearchList({ searchList: BookmarksForValue }));
          }

          // dispatch(bookmarkListApi({ userToken: userToken, requiredValue }));
        } else {
          Toast.fire({
            icon: "success",
            title: response.payload.message,
          });
          const requiredValue = {
            limit: PAGINATION_VALUE.DEFAULT_LIMIT,
            offset: PAGINATION_VALUE.DEFAULT_OFFSET,
          };
          if (props.webListType == WEB_LIST_TYPE.FORSALE) {
            let BookmarksForValue = [...forSaleWebList];
            BookmarksForValue[props.index] = {
              ...BookmarksForValue[props.index],
              is_bookmark: 0,
            };
            dispatch(
              handleForSaleWebList({ forSaleWebList: BookmarksForValue })
            );
          } else if (props.webListType == WEB_LIST_TYPE.WANTED) {
            let BookmarksForValue = [...wantedWebList];
            BookmarksForValue[props.index] = {
              ...BookmarksForValue[props.index],
              is_bookmark: 0,
            };
            dispatch(handleWantedWebList({ wantedWebList: BookmarksForValue }));
          } else if (props.webListType == WEB_LIST_TYPE.JOBOFFER) {
            let BookmarksForValue = [...jobOfferWebList];
            BookmarksForValue[props.index] = {
              ...BookmarksForValue[props.index],
              is_bookmark: 0,
            };
            dispatch(
              handleJobOfferWebList({ jobOfferWebList: BookmarksForValue })
            );
          } else if (props.webListType == WEB_LIST_TYPE.JOBSEEKERS) {
            let BookmarksForValue = [...jobSeekerWebList];
            BookmarksForValue[props.index] = {
              ...BookmarksForValue[props.index],
              is_bookmark: 0,
            };
            dispatch(
              handleJobSeekerWebList({ jobSeekerWebList: BookmarksForValue })
            );
          } else if (props.webListType == WEB_LIST_TYPE.BOOKMARK) {
            let BookmarksForValue = [...bookmarkList];
            BookmarksForValue.splice(props.index, 1);
            dispatch(handleBookmarkList({ bookmarkList: BookmarksForValue }));
          } else if (props.webListType == WEB_LIST_TYPE.SEARCH) {
            let BookmarksForValue = [...searchList];
            BookmarksForValue[props.searchIndex] = {
              ...BookmarksForValue[props.searchIndex],
            };
            let searchDetail = BookmarksForValue[props.searchIndex].detail;
            searchDetail = [{ ...searchDetail[0], is_bookmark: 0 }];
            BookmarksForValue[props.searchIndex] = {
              ...BookmarksForValue[props.searchIndex],
              detail: searchDetail,
            };
            BookmarksForValue = [...BookmarksForValue];
            dispatch(handleSearchList({ searchList: BookmarksForValue }));
          }
          // dispatch(bookmarkListApi({ userToken: userToken, requiredValue }));
        }
      } else if (response.payload.status == STATUS_CODES.BAD_REQUEST) {
        Toast.fire({
          icon: "error",
          title: response.payload.data.message,
        });
      } else if (response.payload.status === STATUS_CODES.INVALID_TOKEN) {
        Toast.fire({
          icon: "error",
          title: t("SESSION_EXPIRE"),
        });
        dispatch(userLogoutClear());
        dispatch(guestUserLogin());
        navigate("/login");
      }
    });
  }
  return (
    <>
      {" "}
      <section>
        {props.isBookmark == 0 ? (
          <Icon
            icon="ic:baseline-bookmark-border"
            color="black"
            width="28"
            height="28"
            cursor={"pointer"}
            onClick={() => {
              if (
                props.isApproved == 1 &&
                Object.keys(currentUser).length !== 0
              ) {
                handleAddBookmark(props.id, BOOK_ACTION_TYPE.ADD);
              } else if (!Object.keys(currentUser).length) {
                setShowAlertPopup(true);
              }
            }}
          />
        ) : (
          <Icon
            icon="ic:baseline-bookmark"
            color="black"
            width="28"
            height="28"
            cursor={"pointer"}
            onClick={() => {
              handleAddBookmark(props.id, BOOK_ACTION_TYPE.REMOVE);
            }}
          />
        )}
      </section>
      {shwoAlertPopup && <LoginAlertModel modalValue={true} bookmark={true} />}
    </>
  );
}

export default AddBookmarks;
